import React from 'react';

class ProductImages extends React.Component{
    constructor(props) {
      super(props);
      this.state = { selected_image: this.props.images[0]};
    }

    changeImage(e,selected_image_index){
        e.preventDefault();
        this.setState({selected_image: this.props.images[selected_image_index]});
    }

    render() {
        return (
            <div className="product-images">
                <div className="main-image">
                    <img src={this.state.selected_image} className="main-image"/>
                </div>
                <div className="image-thumb-container flexbox-space-between no-justify flex-wrap">
                    {this.props.images.map((image, index)=>(
                        <a href="#" className={"image-thumb " + (image===this.state.selected_image?'active':'')} key={index} onClick={(e)=>this.changeImage(e, index)}>
                            <img src={image} />
                        </a>
                    ))}
                </div>
            </div>
        )
    }
}

export default ProductImages;