import React from 'react';
import ProductCartLink from './common_view_components/ProductCartLink';
import LS from './LocalStorage';
import {navigate} from 'gatsby'

class ProductBuyForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {product: this.props.product};
        this.content = this.props.content;
    }
    
    selectorChanged(e){
        this.setState({product:{ ...this.state.product,number_of_owners: e.currentTarget.value}});
    }

    addItem(item){
        LS.addItem('products',item);
        navigate('/cart');
    }

    render(){
        return(
              <div className="product-copy">
                    <h1 className="product-name">{this.state.product.product_name}</h1>
                  {/*  <span className="deal-tag">Save SEK 8,000</span>
                    <span className="deal-tag">15 Friend Groups Got it</span> */}

                    <h2 className="product-description">{this.state.product.description_intro}</h2>

                    <div className="product-price-grouping flexbox-space-between">
                        <div className="product-price">
                            <img src="/images/icon-coins.svg" alt="Price icon"/>
                            <strong>{this.state.product.product_price}</strong>
                            <small>{this.content.price_label}</small>
                        </div>
                        <div className="product-grouping">
                            <img src="/images/icon-group.svg" alt="Group icon"/>
                            <select onChange={this.selectorChanged.bind(this)} value={this.state.product.number_of_owners}>
                                {[2,3,4,5,6,7,8,9].map((val)=>(
                                    <option value={val}  key={val} >{val} {this.content.friends}</option>
                                ))}
                            </select>
                            <small>{this.content.group_label}</small>
                        </div>
                    </div>

                    <ProductCartLink content={this.content} product={this.state.product}  addItem={this.addItem}/>
              </div>
        )
    }
}

export default ProductBuyForm;