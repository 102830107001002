import React from 'react';
import {graphql} from 'gatsby'
import { Tabs } from 'antd';
import {HTMLContent} from '../components/Content'
import Layout from '../components/Layout';
import ProductImages from '../components/ProductImages';
import PopularProducts from '../components/PopularProducts';
import ProductBuyForm from '../components/ProductBuyForm';
import FeatureComponent from '../components/common_view_components/FeatureComponent';


const ProductPageTemplate = ({data})=> {
    const product = data.productDetails.frontmatter.product;
    const content = data.cartContent.frontmatter.cart_content;
    const features = data.featuresContent.frontmatter.why_sharing.features;
    const why_product_sharing = data.featuresContent.frontmatter.why_sharing.product_paragraphs
    const TabPane  = Tabs.TabPane;

    return (
        <Layout>

            <section className="main-section product-details-page">
                <div className="container">
                    <a href="/#shop" className="back-link"><img src="/images/icon-arrow-left.svg"/>{content.back_to_all_produts}</a>
                </div>
                <div className="container flexbox-space-between align-top">
                    <ProductImages images={product.product_images} />

                    <ProductBuyForm product={product} content={content} />
                </div>

                <div className="container tab-container">
                    <div className="tab-links">

                    <Tabs defaultActiveKey="1"   >
                        <TabPane tab="Varför köpa tillsammans" key="1">
                            <div className="tab-content flexbox-space-between flex-wrap align-top">
                                <div className="section-copy">
                                    {     why_product_sharing.map((description, index)=>(
                                            <div key={index}>
                                                <h3>{description.header}</h3>
                                                <p className="subtitle-paragraph">{description.paragraph}</p>
                                            </div>
                                          ))

                                    }
                                </div>

                                <div className="section-feature-list flexbox-space-between align-top no-margin">
                                    {
                                        features.map((feature, index)=>(
                                            <FeatureComponent feature={feature} key={index} />
                                        ))
                                    }
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tab="Produktbeskrivning" key="2">
                            <div className="tab-content">
                                <div className="section-copy full-width">
                                    
                                     <HTMLContent className="subtitle-paragraph" content={product.description}></HTMLContent>
                                    
                                    
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tab="Specifikation" key="3" >
                            <div className="tab-content" className="tech-specs">
                                <div className="section-copy full-width ">
                                    <HTMLContent className="subtitle-paragraph" content={product.tech_details}></HTMLContent>
                                </div>

                            </div>
                        </TabPane>
                           
                        </Tabs>
                    </div>
                </div>
            </section>

            {/* <PopularProducts   /> */}
        </Layout>
    );
};

export default ProductPageTemplate;


export const ProductPageQuery = graphql`
    query ProductPage($id: String!){
        
        cartContent: markdownRemark(frontmatter: { path: { eq: "/product" } }) {
            frontmatter{
                cart_content{
                    text1 
                    text2 
                    price_label 
                    group_label 
                    friends
                    back_to_all_produts
                }            
            }       
        }
        
        featuresContent: markdownRemark(frontmatter: { path: { eq: "/" } }) {
            frontmatter{
                why_sharing{
                     features{
                        img
                        title
                        details
                     }
                     product_paragraphs{
                        header
                        paragraph
                     }
                }            
            }       
        }
           
        productDetails: markdownRemark(id: { eq: $id }) {
              frontmatter {          
                  product{
                    product_link_name
                    product_price
                    number_of_owners
                    product_name
                    currency
                    price_per_person_label
                    product_images 
                    description_intro                    
                    description 
                    tech_details 
                    type_of_group
                  }
              }
        }        
    }
`